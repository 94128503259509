<template>
  <div>
    <div v-if="fetching">
      <Loading />
    </div>
    <template v-else>
      <div class="page">
        <div class="page__title">
          {{ getModuleConfig("completed_page.form_title") }}
        </div>
        <div
          class="page__desc"
          v-if="getModuleConfig('completed_page.form_desc')"
        >
          {{ getModuleConfig("completed_page.form_desc") }}
        </div>
        <template v-if="bookings.length">
          <ul>
            <li class="s-mb-3" v-for="(booking, i) in bookings" :key="i">
              <Item
                :booking="booking"
                :show-image="false"
                :field-setting="fieldSetting"
                :booking-status-mapping="bookingStatusMapping"
              />
            </li>
          </ul>
          <Pagination v-model="page" :total-page="lastPage" />
        </template>
        <div class="s-pt-40 s-text-center" v-else>
          <img class="s-mb-3" src="@/assets/images/liff/empty.svg" />
          <p class="s-text-gray">目前無預約資料</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import Item from "@/components/Page/Liff/Booking/Item";
import Pagination from "@/components/Page/Liff/Shared/Pagination";
import bookingApi from "@/apis/liff/v2/booking";
import bookingMixin from "@/mixins/liff/booking";

export default {
  mixins: [bookingMixin],
  computed: {
    startDate() {
      return this.$route.query.startAt;
    },
    endDate() {
      return this.$route.query.endAt;
    },
  },
  components: {
    Loading,
    Item,
    Pagination,
  },
  data() {
    return {
      fetching: false,
      bookings: [],
      page: +this.$route.query.page || 1,
      lastPage: 1,
    };
  },
  watch: {
    page() {
      this.fetchBookings();
    },
    startDate() {
      this.fetchBookings();
    },
    endDate() {
      this.fetchBookings();
    },
  },
  mounted() {
    this.fetchProvider().then(() => {
      this.init();
      this.setQuery();
      this.fetchBookings();
    });
  },
  methods: {
    async fetchBookings() {
      this.fetching = true;

      try {
        const data = await bookingApi.getList({
          page: this.page,
          status: this.statusFilterMapping.completed.join(","),
          start_at: this.startDate,
          end_at: this.endDate,
          preset_id: this.presetId,
        });
        this.bookings = data.data;
        this.lastPage = data.lastPage;
      } catch (error) {
        console.error(error);
      }

      this.fetching = false;
    },
  },
};
</script>
